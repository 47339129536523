import React from 'react'
import MarketBanner from 'components/MarketBanner'

import BannerImg from 'static/bannerScreen.png'
import Flex from 'components/Flex'
import Title from 'components/Title'
import { useGoogleAnalytics4 } from 'context/GoogleAnalytics4Context'

import styles from './styles.module.css'

const Banner = () => {
  const ga4 = useGoogleAnalytics4()

  return (
    <div className={styles.banner}>
      <img className={styles.image} src={BannerImg} alt="Thisissand app icon" />

      <div className={styles.content}>
        <Title>Download Thisissand App</Title>

        <p>Get Thisissand app to create your own profile and access special features.</p>

        <Flex mt="2em">
          <MarketBanner
            small={true}
            type="ios"
            alt="Apple Store"
            url="https://apps.apple.com/app/thisissand/id569414555"
            onClick={() => {
              const event = {
                category: 'link',
                action: 'follow-thisissand-app-link',
                label: 'app-store',
              }
              ga4.trackEvent(event)
            }
            }
          />

          <MarketBanner
            ml="1em"
            small={true}
            type="android"
            alt="Google Play"
            url="https://play.google.com/store/apps/details?id=com.beigeelephant.thisissand"
            onClick={() => {
              const event = {
                category: 'link',
                action: 'follow-thisissand-app-link',
                label: 'google-play',
              }
              ga4.trackEvent(event)
            }
            }
          />
        </Flex>
      </div>
    </div>
  )
}

export default Banner
